<template>
  <v-expansion-panel>
    <v-expansion-panel-content>
      <div slot="header">Filtres</div>
      <v-card class="pa-3">
        <v-text-field
          label='Rechercher un / des tags (séparés par une virgule)'
          v-model='tags'
          clearable
        >
        </v-text-field>
        <v-text-field
          label='Rechercher un / des types (séparés par une virgule)'
          v-model='types'
          clearable
        >
        </v-text-field>
        <v-select
          :items='Object.keys(formatItems)'
          v-model='format'
          label='Format'
        >
        </v-select>
        <v-select
          :items='Object.keys(statusItems)'
          v-model='status'
          label='Status'
          multiple
        >
        </v-select>
        <v-select
          :items='Object.keys(sortItems)'
          v-model='sort'
          label='Trier par'
        >
        </v-select>
        <v-switch
          label='Test(s) seulement'
          v-model='testFilter'
        >
        </v-switch>
      </v-card>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>
<script>
import { mapActions, mapMutations } from 'vuex';

export default {
  data: () => ({
    timeout: null,
    formatItems: {
      Tous: '',
      PDF: 'PDF',
      Excel: 'EXCEL',
    },
    statusItems: {
      Erreur: 'ERROR',
      'En cours': 'PENDING',
      Succès: 'COMPLETE',
    },
    sortItems: {
      'Date de création': 'createdAt:desc',
      'Date de création inversée': 'createdAt:asc',
      'Date de mise à jour': 'updatedAt:desc',
      'Date de mise à jour inversée': 'updatedAt:asc',
      'Temps de génération': 'timeToGenerate:desc',
      'Temps de génération inversé': 'timeToGenerate:asc',
    },
  }),
  methods: {
    ...mapActions({
      updateFilters: 'documentFilters/updateFilters',
    }),
    ...mapMutations({
      setTags: 'documentFilters/setTags',
      setSort: 'documentFilters/setSort',
      setFormat: 'documentFilters/setFormat',
      setTypes: 'documentFilters/setTypes',
      setTest: 'documentFilters/setTest',
      setStatus: 'documentFilters/setStatus',
    }),
  },
  computed: {
    tags: {
      get() {
        return this.$store.state.documentFilters.tags.join(',');
      },
      set(value) {
        if (this.timeout) clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          this.setTags(value ? value.split(',') : []);
        }, 1000);
      },
    },
    sort: {
      get() {
        return Object.keys(this.sortItems).find(key =>
          this.sortItems[key] === this.$store.state.documentFilters.sort);
      },
      set(value) {
        this.setSort(value ? this.sortItems[value] : null);
      },
    },
    status: {
      get() {
        return this.$store.state.documentFilters.status.map(sta =>
          Object.keys(this.statusItems).find(key => this.statusItems[key] === sta));
      },
      set(values) {
        const status = values.map(value => this.statusItems[value]);
        this.setStatus(status || null);
      },
    },
    format: {
      get() {
        return Object.keys(this.formatItems).find(key =>
          this.formatItems[key] === this.$store.state.documentFilters.format);
      },
      set(value) {
        this.setFormat(value ? this.formatItems[value] : null);
      },
    },
    types: {
      get() {
        return this.$store.state.documentFilters.types.join(',');
      },
      set(value) {
        if (this.timeout) clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          this.setTypes(value ? value.split(',') : []);
        }, 1000);
      },
    },
    testFilter: {
      get() {
        return this.$store.state.documentFilters.test;
      },
      set(value) {
        this.setTest(value);
      },
    },
  },
};
</script>
