<template>
  <v-dialog
    v-model="dialog"
    width="1100"
  >
    <v-btn
      icon
      slot="activator"
    >
      <v-icon>info</v-icon>
    </v-btn>

    <v-card>
      <v-card-title
        class="headline"
        v-bind:class="getColor()"
        primary-title
      >
        <v-layout row>
          <v-icon class="mr-2">picture_as_pdf</v-icon>
          {{ document.name }}
        </v-layout>
      </v-card-title>
      <v-card-text>
        <div class="mb-3">
          <span class="grey--text">Ajouté le: {{ document.createdAt | fullDate }}</span><br>
          <span class="grey--text">Mise à jour le: {{ document.updatedAt | fullDate }}</span><br>
          <span class="grey--text">
            Temps de génération: {{ document.timeToGenerate | sec }}s
          </span><br>
          <span class="grey--text">IP: {{ document.ip }}</span><br>
          <span class="grey--text">Client: {{ document.client.name }}</span><br>
          <span class="grey--text" v-if="document.test">Mode test activé.</span><br>
          <span class="grey--text font-italic" v-if="document.test">
            Les documents générés hors-production sont toujours en mode test.</span>
        </div>
        <div class="mb-3" v-if="document.status === 'COMPLETE'">
          <v-layout row justify-center wrap>
            <v-layout column justify-center wrap>
              <v-btn
                outline
                target="_blank"
                v-bind:color="getColor()"
                v-for="(sourceLink, index) of document.sourceLinks"
                v-bind:href="sourceLink"
                :key="sourceLink"
              >
                Télécharger la source {{ index + 1 }}
              </v-btn>
            </v-layout>
            <v-btn
              outline
              target="_blank"
              v-bind:color="getColor()"
              v-bind:href="document.downloadLink"
            >
              Télécharger le document
            </v-btn>
          </v-layout>
        </div>
        <div class="mb-3">
          <h3>Request</h3>
          <pre>{{ document.request | pretty }}</pre>
        </div>
        <div class="mb-3">
          <h3>Logs</h3>
          <pre>{{ document.logs }}</pre>
        </div>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          v-bind:color="getColor()"
          flat
          @click="dialog = false"
        >
          Fermer
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    getColor() {
      if (this.document.status === 'COMPLETE') return 'success';
      else if (this.document.status === 'PENDING') return 'info';
      return 'error';
    },
  },
  props: {
    document: Object,
  },
  filters: {
    pretty(value) {
      return JSON.stringify(JSON.parse(value), null, 2);
    },
  },
};
</script>

<style lang="scss">
pre {
  overflow: auto;
}
</style>
