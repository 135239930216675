<template>
  <v-layout justify-center column class="mt-4">
    <v-layout justify-center>
      <v-flex xs12 sm6 md4 xl2>
        <DocumentFilters></DocumentFilters>
      </v-flex>
    </v-layout>
    <div v-if="documents.length === 0" class="text-xs-center mt-4">
      Aucun document...
    </div>
    <v-container grid-list-xl>
      <v-layout row wrap>
        <v-flex
          xs12 sm6 md4 xl2
          v-for="document in documents"
          :key="document._id"
        >
          <Document v-bind:document="document"></Document>
        </v-flex>
      </v-layout>
    </v-container>
    <v-layout justify-center>
      <v-pagination
        v-model="page"
        :length="totalPages"
        :total-visible="7"
        @input="nextPage"
      >
      </v-pagination>
    </v-layout>
    <v-layout justify-center>
      <v-flex xs6 sm4 md2 xl1>
        <v-select
          :items="nbPerPageItems"
          v-model="nbPerPage"
          label="Par page"
        ></v-select>
      </v-flex>
    </v-layout>
  </v-layout>
</template>

<script>
import { mapMutations, mapActions } from 'vuex';
import Document from '@/components/Document.vue';
import DocumentFilters from '@/components/DocumentFilters.vue';

export default {
  name: 'list',
  components: {
    Document,
    DocumentFilters,
  },
  data: () => ({
    totalPages: 1,
    page: 1,
    nbPerPageItems: [5, 10, 20, 40],
    currentFilters: {
      sort: 'createdAt:desc',
    },
  }),
  sockets: {
    newDocument() {
      this.getDocuments(this.filters)
        .then(this.updatePagination)
        .catch(() => {
          this.setSnack('Erreur dans la récupération des documents.');
        });
    },
  },
  methods: {
    nextPage(page) {
      this.params = {
        ...this.filters,
        page,
      };
      this.getDocuments(this.params).then(this.updatePagination);
    },
    updatePagination(res) {
      this.totalPages = res.totalPages;
      this.page = res.page;
    },
    ...mapMutations({
      setSnack: 'snackbar/setSnack',
      setLimit: 'documentFilters/setLimit',
    }),
    ...mapActions({
      getDocuments: 'documents/getDocuments',
    }),
  },
  computed: {
    documents() { return this.$store.getters['documents/getDocuments']; },
    filters() { return this.$store.getters['documentFilters/getFiltersAsURLParams']; },
    nbPerPage: {
      get() {
        return this.$store.state.documentFilters.limit;
      },
      set(value) {
        this.setLimit(value);
      },
    },
  },
  created() {
    this.getDocuments(this.filters)
      .then(this.updatePagination)
      .catch(() => {
        this.setSnack('Erreur dans la récupération des documents.');
      });

    this.$store.watch((state, getters) => getters['documentFilters/getFiltersAsURLParams'], () => {
      this.getDocuments(this.filters)
        .then(this.updatePagination)
        .catch(() => {
          this.setSnack('Erreur dans la récupération des documents.');
        });
    });
  },
};
</script>
