<template>
  <v-badge left overlap v-bind:color="getColor()" class="d-block">
    <span slot="badge">
      <v-icon v-if="document.status === 'COMPLETE'">done</v-icon>
      <template v-if="document.status === 'PENDING'">···</template>
      <v-icon v-if="document.status === 'ERROR'">clear</v-icon>
    </span>
    <v-hover>
      <v-card
        slot-scope="{ hover }"
        :class="`elevation-${hover ? 12 : 2}`"
      >
        <v-card-title primary-title>
          <v-layout column>
            <div class="headline">{{ document.name }}</div>
            <div>
              <span class="grey--text">{{ document.createdAt | fullDate | capitalize }}</span>
              <br>
              <span class="grey--text">PrinceXML version: {{ document.princeVersion || 10 }}</span>
              <template v-if="document.test">
                <br>
                <span class="grey--text">Mode test activé</span>
              </template>
            </div>
          </v-layout>
        </v-card-title>
        <v-card-text>
          <div v-if="document.tags && document.tags.length > 0">
            <div class="subheading">Tags</div>
            <div class="text-xs-center">
              <v-btn
                v-for="tag in document.tags"
                :key="tag"
                v-on:click="searchTag(tag)"
                round
                color="primary"
                small
                dark
              >{{ tag }}</v-btn>
            </div>
          </div>
          <div v-if="document.type">
            <div class="subheading">Type</div>
            <div class="text-xs-center">
              <v-btn
                round
                color="secondary"
                small
                dark
                v-on:click="searchType(document.type)"
              >{{ document.type }}</v-btn>
            </div>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            v-if="document.status === 'COMPLETE'"
            flat
            icon
            color="success"
            :href="document.downloadLink"
            target="_blank"
          >
            <v-icon>cloud_download</v-icon>
          </v-btn>
          <v-btn
            flat
            icon
            color="error"
            @click="removeDocument"
            :loading="loading"
            :disabled="loading"
          >
            <v-icon>clear</v-icon>
          </v-btn>
          <DocumentDialog v-bind:document="document"></DocumentDialog>
        </v-card-actions>
      </v-card>
    </v-hover>
  </v-badge>
</template>

<script>
import DocumentDialog from '@/components/DocumentDialog.vue';
import { mapActions, mapMutations } from 'vuex';

export default {
  components: {
    DocumentDialog,
  },
  data: () => ({
    loading: false,
  }),
  methods: {
    getColor() {
      if (this.document.status === 'COMPLETE') return 'success';
      else if (this.document.status === 'PENDING') return 'info';
      return 'error';
    },
    removeDocument() {
      this.loading = true;
      this.deleteDocument(this.document)
        .then(() => {
          this.setSnack('Document supprimé');
        })
        .catch(() => {
          this.setSnack('Erreur dans la suppression du document');
        })
        .finally(() => {
          this.loading = false;
        });
    },
    searchType(type) {
      this.addType(type);
    },
    searchTag(tag) {
      this.addTag(tag);
    },
    ...mapActions({
      deleteDocument: 'documents/deleteDocument',
      updateFilters: 'documentFilters/updateFilters',
    }),
    ...mapMutations({
      setSnack: 'snackbar/setSnack',
      addTag: 'documentFilters/addTag',
      addType: 'documentFilters/addType',
    }),
  },
  props: {
    document: Object,
  },
};
</script>
